.main-navigation {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.main-navigation a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
  padding: 8px 12px;
}

.main-navigation a:hover {
  color: #0056b3;
}

.body-wrapper {
  padding-top: 60px; /* Match the height of the navigation */
}

@media (max-width: 768px) {
  .nav-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 10px;
  }
  
  .main-navigation a {
    white-space: nowrap;
  }
}
